<template>
  <focus-trap
    :active="isFocusTrapActive"
    :escapeDeactivates="false"
    :clickDeactivates="false"
  >
    <div
      class="reservation-owners-search-popup"
      @keyup.esc.stop="close"
      tabindex="-1"
    >
      <popup
        :title="'検索'"
        :shortFlg="true"
        @close="close"
        @click-close-mark="close"
        :buttons="['閉じる']"
      >
        <template v-slot:content>
          <div class="content">
            <div class="search-input">
              <input
                type="text"
                :placeholder="placeholder"
                v-model="inputText"
                data-e2e="owners-search-text-box"
                ref="searchInput"
              />
            </div>
            <div class="tab">
              <div class="tab-item-wrapper">
                <div class="tab-item" data-e2e="item-count">
                  飼主<span>（{{ ownersListData.length }}件）</span>
                </div>
              </div>
            </div>
            <div class="list-wrapper">
              <div class="list-header">
                <div class="list-header-label">飼主ID</div>
                <div class="list-header-label">飼主名</div>
              </div>
              <template>
                <dynamic-scroller
                  class="list-body"
                  :items="ownersListData"
                  :min-item-size="50"
                >
                  <template v-slot="{ item, index, active }">
                    <dynamic-scroller-item
                      :item="item"
                      :active="active"
                      :data-index="index"
                      ><div
                        class="list-item"
                        :class="{ selected: item.id === dispOwnerId }"
                        @click="clickOwner(item.id)"
                      >
                        <div
                          class="selected-check-mark"
                          v-if="item.id === dispOwnerId"
                        ></div>
                        <div class="list-item-data show-id">
                          {{ item.showId }}
                        </div>
                        <div class="list-item-data name">{{ item.name }}</div>
                      </div>
                    </dynamic-scroller-item>
                  </template>
                </dynamic-scroller>
              </template>
            </div>
          </div>
        </template>
      </popup>
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import { kataToHira } from '@/utils/convert_string'
import { compareShowId } from '@/utils/sort_show_id'
import { FocusTrap } from 'focus-trap-vue'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'

export default {
  name: 'ReservationOwnersSearchPopup',
  components: {
    Popup,
    FocusTrap,
    DynamicScroller,
    DynamicScrollerItem
  },
  props: {
    dispOwnerId: { type: Number }
  },
  data() {
    return {
      searchText: '',
      placeholder: '飼主ID、飼主名',
      timeoutId: null,
      isFocusTrapActive: false
    }
  },
  mounted() {
    this.isFocusTrapActive = true
    this.$nextTick(() => {
      this.timeoutId = setTimeout(() => {
        this.focusSearchInput()
      }, '100')
    })
  },
  computed: {
    ownersListData() {
      const word = this.searchText.trim()
      const owners = this.$store.getters['owners/getData']
      return owners
        .filter(owner => {
          return this.ownerFilter(owner, word)
        })
        .map(owner => {
          return {
            id: owner.id,
            showId: owner.showId,
            name: owner.lastName + ' ' + owner.firstName
          }
        })
        .sort((a, b) => compareShowId(a.showId, b.showId))
    },
    inputText: {
      get() {
        return this.searchText
      },
      set(val) {
        clearTimeout(this.timeoutId)
        this.timeoutId = setTimeout(() => {
          this.searchText = val
        }, 300)
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeoutId)
  },
  methods: {
    ownerFilter(owner, word) {
      return (
        (owner.lastName + owner.firstName)
          .toLowerCase()
          .includes(word.toLowerCase()) ||
        (owner.lastNameKana + owner.firstNameKana).includes(word) ||
        kataToHira(owner.lastNameKana + owner.firstNameKana).includes(word) ||
        (owner.showId + '').includes(word)
      )
    },
    close() {
      this.$emit('closeSearch')
    },
    clickOwner(ownerId) {
      this.$emit('selectOwner', ownerId)
    },
    focusSearchInput() {
      this.$refs.searchInput.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-owners-search-popup {
  .content {
    width: 700px;
    min-height: 470px;
    > .search-input {
      display: flex;
      flex-direction: row;
      margin: auto;
      margin-top: 10px;
      height: 60px;
      > input {
        margin: auto;
        width: 227px;
        height: 33px;
        font-size: 11px;
        padding-left: 15px;
        padding-right: 40px;
        box-sizing: border-box;
        border: solid 2px #{$light-grey};
        border-radius: 17px;
        -webkit-border-radius: 17px;
        -moz-border-radius: 17px;
        border-radius: 17px;
        -webkit-border-radius: 17px;
        -moz-border-radius: 17px;
        background-image: url('../../assets/images/search.png');
        background-size: 18px 18px;
        background-repeat: no-repeat;
        background-position: center right 12px;
      }
    }
    > .tab {
      margin: auto;
      flex-direction: row;
      display: flex;
      text-align: center;
      > .tab-item-wrapper {
        margin: auto;
        margin-top: 10px;
        > .tab-item {
          float: left;
          width: 150px;
          text-align: center;
          font-size: 15px;
          height: 30px;
          border-bottom: 4px solid #{$pumpkin};
          color: #{$pumpkin};
        }
      }
    }
    .list-wrapper {
      width: 700px;
      height: 350px;
      overflow: hidden;
      color: #{$greyish-brown};
      font-size: 15px;
      > .list-header {
        width: 100%;
        height: 40px;
        background-color: #{$white_f8};
        border-top: 1px solid #{$light-grey};
        border-bottom: 1px solid #{$light-grey};
        padding-left: 100px;
        > .list-header-label {
          float: left;
          text-align: left;
          margin-top: 10px;
          width: 290px;
        }
      }
      .list-body {
        width: 100%;
        height: 310px;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .list-item {
        width: 100%;
        height: 50px;
        display: flex;
        padding-left: 100px;
        @include hover();

        > .list-item-data {
          margin: auto 0 auto 0;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 290px;
        }
      }
      .selected {
        background-color: #{$white_f8};
        position: relative;
        > .selected-check-mark {
          position: absolute;
          top: 15px;
          left: 15px;
          width: 20px;
          height: 20px;
          background-image: url('../../assets/images/success.png');
          background-size: 20px 20px;
        }
      }
    }
  }
}
</style>
